'use client';
import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Headline } from '../Headline';
import { StandardCardsGrid } from '../StandardCardsGrid';
import { ALPHABETICAL_SORT_LABEL, DEFAULT_ROOT_CATEGORIES_SLUG } from '@/shared/utils/constants';
import { Badge, Category } from '@/shared/api/arena-data';
import { useUnit } from 'effector-react';
import { $allCategoryData } from '@/app.model';
import { ShortGameModelFromFeed } from "@/root/app/[locale]/games/[slug]/types";

type Context = {
    setSelectedSort?: Dispatch<SetStateAction<string>>;
    arenaCategories?: Category[];
    categories?: string[];
    currentFilter?: string | null;
    rootCategorySlug: string | null;
    categoryLinks?: boolean;
};

export const HeadlineContext = createContext<Context>({ rootCategorySlug: DEFAULT_ROOT_CATEGORIES_SLUG });

export const HeadlineCardsGrid = (props: {
    feed: ShortGameModelFromFeed[],
    arenaCategories?: Category[];
    rootCategorySlug?: string | null;
    initialFilter?: string | null;
    title?: string | null;
    query?: string | null;
    scrollingLoad?: boolean;
    hideCategories?: boolean;
    categoryLinks?: boolean;
    arenaBadges?: Badge[];
}) => {
    const allCategoryPage = useUnit($allCategoryData);
    const [games, setGames] = useState<ShortGameModelFromFeed[]>([]);
    const [currentFilter, setCurrentFilter] = useState<string | undefined | null>(
        props?.initialFilter ?? allCategoryPage.name
    );
    const [selectedSort, setSelectedSort] = useState<string>(ALPHABETICAL_SORT_LABEL);

    const sortOptions = useMemo(() => {
        return createSortOptions(games);
    }, [games]);

    const categories = useMemo(() => {
        if (props?.hideCategories) {
            return [];
        }
        const allCategories = props.feed
            .map((g) => g?.meta?.categories.map((category) => category.toLocaleLowerCase().trim()))
            .flat();
        return [allCategoryPage.name!, ...Array.from(new Set(allCategories))];
    }, [props.feed]);

    function createSortOptions(feed: ShortGameModelFromFeed[]) {
        const uniqueOptions = Array.from(
            new Set(
                feed
                    .filter((g) => g?.meta?.badges?.length > 0)
                    .map((g) => g?.meta?.badges)
                    .flat()
                    .sort((a, b) => a.localeCompare(b))
            )
        );
        return [ALPHABETICAL_SORT_LABEL, ...uniqueOptions];
    }

    function sortFeed(by: string, optionalFeed?: ShortGameModelFromFeed[]) {
        const sourceFeed = optionalFeed ?? games;

        // If sort doesn't exist, default to Alphabetical
        if (!sortOptions.includes(by)) {
            by = ALPHABETICAL_SORT_LABEL;
        }

        setSelectedSort(by);
        let sortedFeed = [];
        if (by === ALPHABETICAL_SORT_LABEL) {
            sortedFeed = sourceFeed.sort((a, b) => a.name.localeCompare(b.name));
        }
        // Sort by badge
        else {
            sortedFeed = sourceFeed.sort((a, b) => {
                const badgeExistsA = a?.meta?.badges?.includes(by);
                const badgeExistsB = b?.meta?.badges?.includes(by);
                if (badgeExistsA && !badgeExistsB) {
                    return -1;
                }
                if (!badgeExistsA && badgeExistsB) {
                    return 1;
                }
                return a.name.localeCompare(b.name);
            });
        }
        setGames([...sortedFeed]);
    }

    function filterFeed(e: any) {
        const selectedCategory: string = e.target.value;
        const foundCategory = categories?.find((c) => c.toLocaleLowerCase() === selectedCategory.toLocaleLowerCase());

        e.target.checked = true;
        setCurrentFilter(foundCategory);

        if (foundCategory === allCategoryPage.name) {
            sortFeed(selectedSort, props?.feed);
            return;
        }

        const filteredFeed = [...props.feed.filter((g) =>
            g?.meta?.categories.map(c => c.toLocaleLowerCase().trim()).includes(selectedCategory.toLocaleLowerCase()))];
        sortFeed(selectedSort, filteredFeed);
    }

    useEffect(() => {
        sortFeed(ALPHABETICAL_SORT_LABEL, props?.feed);
    }, [props?.feed]);

    useEffect(() => {
        if (props?.initialFilter) {
            filterFeed({ target: { value: props?.initialFilter } });
        }
    }, [props?.initialFilter]);

    return (
        <HeadlineContext.Provider
            value={{
                categories,
                arenaCategories: props?.arenaCategories,
                setSelectedSort,
                currentFilter: currentFilter,
                rootCategorySlug: props?.rootCategorySlug ?? DEFAULT_ROOT_CATEGORIES_SLUG,
                categoryLinks: props?.categoryLinks,
            }}
        >
            <Headline
                title={props?.title}
                query={props?.query}
                sortOptions={sortOptions}
                onSort={sortFeed}
                onFilter={filterFeed}
            />
            {games.length > 0 && (
                <StandardCardsGrid
                    games={games}
                    rootCategorySlug={props?.rootCategorySlug ?? DEFAULT_ROOT_CATEGORIES_SLUG}
                    scrollingLoad={props?.scrollingLoad}
                    arenaBadges={props?.arenaBadges ?? []}
                />
            )}
        </HeadlineContext.Provider>
    );
};
