'use client'
import { useContext } from "react";
import { CategoriesSection } from "../CategoriesSection";
import { Dropdown } from "../Dropdown";
import styles from "./styles.module.css";
import { HeadlineContext } from "../HeadlineCardsGrid";
import classNames from "classnames";

type Props = {
    title?: string | null;
    query?: string | null;
    sortOptions?: any[];
    onSort?: Function;
    onFilter?: Function;
}

export const Headline = (props: Props) => {
    const {
        categories, setSelectedSort
    } = useContext(HeadlineContext);
    return <div className={classNames(styles.header, 'headers')}>
        <p className={styles.headline}>
            {props.title} {props?.query && <span className={styles.query}>“{props.query}”</span>}
        </p>
        {props?.sortOptions?.length &&
            <Dropdown items={props?.sortOptions} onUpdate={(e: string) => { setSelectedSort?.(e); props?.onSort?.(e) }} />
        }
        {categories?.length && categories?.length > 0
            ? <CategoriesSection mobileCarousel={true} onSelect={(e: any) => props?.onFilter?.(e)} />
            : null}
    </div>
}