'use client';
import { CategoryList } from "@/features/category-list";
import styles from "./styles.module.css";

export const CategoriesSection = ({ onSelect, mobileCarousel }: any) => {
    function onInput(e: any) {
        onSelect(e);
    }

    return (
        <fieldset className={styles["categories"]} onInput={onInput}>
            <span className={styles["label"]}>Categories</span>
            <span className={styles["carousel-container"]}>
                <CategoryList mobileCarousel={mobileCarousel} />
            </span>
        </fieldset>
    );
}